import {
  useCallback,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren
} from 'react';
import { RouterEventDispatcherContext } from './internal/RouterEventDispatcherContext';
import {
  RouterEventManagerContext,
  type IRouterEventManager,
  type RouterEventArgs,
  type RouterEventHandler,
  type RouterEventName
} from './internal/RouterEventManagerContext';

/**
 * A provider for managing router event listeners. It provides a context for
 * listening to and dispatching router events.
 */
export const RouterEventProvider: FC<PropsWithChildren> = ({ children }) => {
  const [eventHandlers, setEventHandlers] = useState<
    Record<RouterEventName, Array<RouterEventHandler<RouterEventName>>>
  >({} as Record<RouterEventName, Array<RouterEventHandler<RouterEventName>>>);

  const events: IRouterEventManager = useMemo(
    () => ({
      on: (eventName, callback) => {
        setEventHandlers((existingEventHandlers) => {
          const handlersForEvent = existingEventHandlers[eventName] ?? [];

          handlersForEvent.push(callback);

          existingEventHandlers[eventName] = handlersForEvent;
          return existingEventHandlers;
        });
      },
      off: (eventName, callback) => {
        setEventHandlers((existingEventHandlers) => {
          const handlersForEvent = existingEventHandlers[eventName] ?? [];
          const callbackIdx = handlersForEvent.indexOf(callback);

          if (callbackIdx > -1) {
            handlersForEvent.splice(callbackIdx, 1);
          }

          return existingEventHandlers;
        });
      }
    }),
    [setEventHandlers]
  );

  const fireEvent = useCallback(
    <T extends RouterEventName>(eventName: T, args: RouterEventArgs<T>) => {
      const handlersForEvent = eventHandlers[eventName] ?? [];

      handlersForEvent.forEach((handler) => {
        // Don't know neither why TS didn't accept that this function receives a
        // rest param, or why this assertion can fool it lol.
        (handler as RouterEventHandler<T>)(...args);
      });
    },
    [eventHandlers]
  );

  return (
    <RouterEventDispatcherContext.Provider value={fireEvent}>
      <RouterEventManagerContext.Provider value={events}>
        {children}
      </RouterEventManagerContext.Provider>
    </RouterEventDispatcherContext.Provider>
  );
};

'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { enableStaticRendering } from 'mobx-react-lite';

// Added to resolve Logger error when using in the browser: https://github.com/winstonjs/winston/issues/1354#issuecomment-546271943
import { Datadog } from '@/react/components/Datadog';

import { Osano } from '@/react/components/cookie-preferences';
import Forter from '@/react/components/fraud/Forter';
import DynamicYield from '@/react/components/personalization/DynamicYield';
import GTM from '@/react/components/user-action/GTM';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import {
  ReactHooksWrapper,
  setHook
} from '@/services/utils/react-utils/hook-utils';

import { GlobalProvider } from '@/react/providers/global/GlobalProvider';

import { GlobalErrorAlertProvider } from '@/react/components/core-ui/ErrorAlert/GlobalErrorAlertProvider';
import { RootErrorBoundary } from '@/react/components/errors/boundaries/RootErrorBoundary';
import { TopErrorAlertList } from '@/react/components/errors/devtools/TopErrorAlertList';
import { TopErrorProvider } from '@/react/components/errors/devtools/TopErrorProvider';
import { HydrationProvider } from '@/react/components/utils/HydrationProvider';
import { RouterEventProvider } from '@/react/providers/router-events/RouterEventProvider';
import { RouterEvents } from '@/react/providers/router-events/RouterEvents';
import { Suspense } from 'react';
import { PageProvider } from '@/react/components/templates/context/PageProvider';
import type { IPage } from '@/services/models/Page';
import { NotificationsManager } from '@/react/components/utils/NotificationsManager';
import NextTopLoader from 'nextjs-toploader';
import {
  headerLoadingBarColor,
  headerLoadingBarHeight
} from '@/styles/variables.module.scss';

// Prevent MobX from leaking memory when rendering server-side
enableStaticRendering((typeof window === "undefined"));

export interface IClientRootProps {
  /**
   * The page object that should come from outside the layout.
   */
  page: IPage;
  /**
   * The children of the ClientRoot component.
   */
  children: React.ReactNode;
}

/**
 * This is the top-most Client Component.
 * @throws If there is no page object on the pages service.
 */
const ClientRoot: React.FC<IClientRootProps> = ({ page, children }) => {
  setHook('Router', useRouter);
  return (
    <HydrationProvider>
      <RouterEventProvider>
        <Suspense fallback={null}>
          <RouterEvents />
        </Suspense>
        <TopErrorProvider>
          <TopErrorAlertList />
          <RootErrorBoundary>
            <GlobalErrorAlertProvider>
              <NextTopLoader
                color={headerLoadingBarColor}
                height={parseInt(headerLoadingBarHeight, 10)}
                showSpinner={false}
              />
              <PageProvider page={page}>
                <NotificationsManager>
                  <GlobalProvider>
                    <Osano />
                    <GTM />
                    <Forter />
                    <Datadog />
                    <DynamicYield />
                    <SpeedInsights />
                    <ReactHooksWrapper />
                    {children}
                  </GlobalProvider>
                </NotificationsManager>
              </PageProvider>
            </GlobalErrorAlertProvider>
          </RootErrorBoundary>
        </TopErrorProvider>
      </RouterEventProvider>
    </HydrationProvider>
  );
};

export default ClientRoot;
